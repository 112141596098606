import Swiper from "swiper";
import SwiperCore, { Navigation, Controller, Autoplay } from "swiper/core";

SwiperCore.use([Navigation, Controller, Autoplay]);

(function () {
  const projectsSlider = document.querySelector(".projects-slider");
  if (!projectsSlider) {
    return;
  }

  // breakpoint where swiper will be destroyed
  // and switches to a dual-column layout
  // const breakpoint = window.matchMedia("(min-width:767px)");
  // keep track of swiper instances to destroy later
  let mySwiper;
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  // const breakpointChecker = function () {
  //   // if larger viewport and multi-row layout needed
  //   if (breakpoint.matches === true) {
  //     console.log("matches", mySwiper);
  //     // clean up old instances and inline styles when available
  //     if (mySwiper !== undefined) mySwiper.destroy(true, true);
  //     // or/and do nothing
  //     return;
  //     // else if a small viewport and single column layout needed
  //   } else if (breakpoint.matches === false) {
  //     console.log("not matches");
  //     // fire small viewport version of swiper
  //     return enableSwiper();
  //   }
  // };
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  const enableSwiper = function () {
    mySwiper = new Swiper(".projects-slider", {
      loop: true,
      slidesPerView: 1,
      spaceBetween: "17%",
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        768: {
          loop: false,
          slidesPerView: 4,
          spaceBetween: "17%",
        },
      },
    });
  };
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  // keep an eye on viewport size changes
  // breakpoint.addEventListener("change", breakpointChecker);
  // kickstart
  // breakpointChecker();
  enableSwiper();
})();
